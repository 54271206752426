<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="inmuebles" class="p-datatable-customers" v-model:selection="selectedInmuebles" dataKey="id_inmueble" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['codigo', 'identificador1', 'identificador2', 'identificador3', 'identificador4', 'identificador5', 'clasificacion', 'display', 'nombre_documento', 'nit_documento']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loadingInmuebles">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Inmuebles</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
								<InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="codigo" header="Código" :sortable="true" headerStyle="width: 8%">
					</Column>
					<Column field="identificador1" :sortable="true">
						<template #header>
						<div class="table-header">
							{{ complejo.nivel1 }}
						</div>
						</template>
					</Column>					
					<Column field="identificador2"  :sortable="true" :style="ocultarColumnas.nivel2?'display: none' : ''">
						<template #header>
						<div class="table-header">
							{{ complejo.nivel2 }}
						</div>
						</template>
					</Column>				
					<Column field="identificador3"  :sortable="true" :style="ocultarColumnas.nivel3?'display: none' : ''">
						<template #header>
						<div class="table-header">
							{{ complejo.nivel3 }}
						</div>
						</template>
					</Column>								
					<Column field="identificador4"  :sortable="true" :style="ocultarColumnas.nivel4?'display: none' : ''">
						<template #header>
						<div class="table-header">
							{{ complejo.nivel4 }}
						</div>
						</template>
					</Column>
					<Column field="identificador5"  :sortable="true" :style="ocultarColumnas.nivel5?'display: none' : ''">
						<template #header>
						<div class="table-header">
							{{ complejo.nivel5 }}
						</div>
						</template>
					</Column>												
					<Column field="nombre_documento" header="Emitir documentos a nombre de" headerStyle="width: 20%"/>
					<Column field="clasificacion" header="Clasificación" headerStyle="width: 10%">
					</Column>
					<Column field="ocupado" header="Ocupado" headerStyle="width: 10%" bodyStyle="text-align: center">
						<template #body="slotProps">							
							<i v-if="slotProps.data.ocupado=='N'" class="pi pi-circle-off"></i>
							<i v-if="slotProps.data.ocupado=='S'" class="pi pi-circle-on"></i>							
						</template>	
					</Column>      
					<Column field="activo" header="Activo" headerStyle="width: 10%" bodyStyle="text-align: center">
						<template #body="slotProps">							
							<i v-if="slotProps.data.activo=='N'" class="pi pi-circle-off"></i>
							<i v-if="slotProps.data.activo=='S'" class="pi pi-circle-on"></i>							
						</template>			
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-1" @click="edit(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-1" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
			</div>
			<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
				<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="inmueble">Esta seguro que desea eliminar el inmueble seleccionado?<br><b>[{{ inmueble.display }}]</b>?</span>
				</div>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
					<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteRec" />
				</template>
			</Dialog>
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import MdsService from '../../service/mds.service';
import {FilterMatchMode} from 'primevue/api';

export default {
	data() {
		return {
			inmuebles: null,
			complejo: this.$store.state.auth.currentAppCtx.complejo,
			deleteDialog: false,
			selectedInmuebles: null,
			inmueble: {},		
			ocultarColumnas: {
				nivel1: true, //(this.$store.state.auth.currentAppCtx.complejo.nivel1 == ''),
				nivel2: (this.$store.state.auth.currentAppCtx.complejo.nivel2 == ''),
				nivel3: (this.$store.state.auth.currentAppCtx.complejo.nivel3 == ''),
				nivel4: (this.$store.state.auth.currentAppCtx.complejo.nivel4 == ''),
				nivel5: (this.$store.state.auth.currentAppCtx.complejo.nivel5 == '')
			},
			loading: false,
			loadingInmuebles: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false
		}
	},
	components: {
		Loading
	},		
	mounted() {
		this.loadingInmuebles = true;

		MdsService.getInmuebles(this.$store.state.auth.currentAppCtx, "").then(
			data => {
				this.inmuebles = data;
				this.loadingInmuebles = false;
			}
		).catch(
			data => {
				this.loadingInmuebles = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}
		)		
	},
	methods: {
		openNew() {
			this.$router.push({name: 'inmueble', params: {id: ''}});	
		},
		edit(registro) {			
			this.$router.push({name: 'inmueble', params: {id: registro.id_inmueble}});		
		},
		confirmDelete(registro) {
			this.inmueble = registro;
			this.deleteDialog = true;
		},
		deleteRec() {
			MdsService.eliminarInmueble(this.$store.state.auth.currentAppCtx, this.inmueble).then(() => {
					this.inmuebles = this.inmuebles.filter(val => val.id_inmueble !== this.inmueble.id_inmueble);
					this.deleteDialog = false;
					this.inmueble = {};
					this.$toast.add({severity:'success', summary: 'Exito', detail: 'El registro ha sido eliminado!', life: 3000})
			}
			).catch(
				data => {
					this.deleteDialog = false;
					this.product = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
